.dropzone {
    height: 48px;
    background: var(--color-roxo);
    border-radius: 5px;

    display: flex;
    justify-content: center;
    align-items: center;
    outline: 0;

    cursor: pointer;

    width: 100%;
  }

  .dropzone p {

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: var(--color-button-text);
  }

  .dropzone p svg {
    color: var(--color-button-text);
    width: 24px;
    height: 24px;
    margin: 0 8px;
  }
