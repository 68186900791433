.autocomplete {
    width: 100%;
    padding: 0 !important;
    margin: 0 !important;
}

.autocomplete-label {
    margin: 0.8rem 0;
}


.autocomplete * {
    padding: 0 !important;
    margin: 0 !important;
}

.autocomplete .textfield {

}

.autocomplete .textfield input {
    width: 100%;
    height: 3.6rem;
    border-radius: 0.8rem;
    background: var(--color-input-background);
    border: 1px solid var(--color-line-in-white);
    padding: 0 1.6rem !important;
    outline: 0;
    font: 1rem Poppins;
    
}

.autocomplete input {
    font-size: 1rem;
}
