.select-block {
  position: relative;
}

.select-block {
  margin-top: 1.4rem;
}

.select-block label{
  font-size: 1rem;
}

.select-block select {
  width: 100%;
  height: 3.6rem;
  margin-top: 0.8rem;
  border-radius: 0.8rem;
  background: var(--color-input-background);
  border: 1px solid var(--color-line-in-white);
  padding: 0 1.6rem;
  outline: 0;
  font: 1rem Poppins;
}

.multiple {
  height: 4.8rem;
}
