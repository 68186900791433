.checkbox-list-container {
  margin-top: 1.2rem;
}

.checkbox-list-values {
  margin-top: 1.2rem;
}

.checkbox-list-container .checkBox-select {
  margin: 0.5rem 0;
}