.textarea-block {
    position: relative;
    margin-top: 1.4rem;
  }
  
  .textarea-block +.textarea-block {
    margin-top: 1.4rem;
  }
  
  .textarea-block label{
    font-size: 1rem;
  }
  
  .textarea-block textarea {
    width: 100%;
    height: 4rem;
    min-height: 2rem;
    border-radius: 0.8rem;
    background: var(--color-input-background);
    border: 1px solid var(--color-line-in-white);
    padding: 1.2rem 1.6rem;
    resize: vertical;
    outline: 0;
    font: 1rem Poppins;
  }
  
  .textarea-block:focus-within::after {
    width: calc(100% - 3.2rem);
    height: 2px;
    content: '';
    background: var(--color-primary-light);
    position: absolute;
    left: 1.6rem;
    right: 1.6rem;
    bottom: 7px;
  }