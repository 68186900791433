.input-block {
    position: relative;
}

.input-block {
    margin-top: 1.4rem;
}

.input-block label{
    font-size: 1rem;
}

.input-block input {
    width: 100%;
    height: 3.6rem;
    margin-top: 0.8rem;
    border-radius: 0.8rem;
    background: var(--color-input-background);
    border: 1px solid var(--color-line-in-white);
    padding: 0 1.6rem;
    outline: 0;
    font: 1rem Poppins;
}

/* .input-block:focus-within::after {
    width: calc(100% - 3.2rem);
    height: 2px;
    content: '';
    background: var(--color-primary-light);
    position: absolute;
    left: 1.6rem;
    right: 1.6rem;
    bottom: 0;
} */