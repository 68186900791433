:root {

  /* Roxo: #5f33ff
  Azul: #2ef5ff */
  
  --color-background: #F0F0F7;

  --color-primary-lighter: #3b3b49;
  --color-primary-light: #916BEA;
  --color-primary: #252535;
  --color-primary-dark: #21212f;
  --color-primary-darker: #6842C2;

  --color-terciary-lighter: #9871F5;
  --color-terciary-light: #916BEA;
  --color-terciary: #8257E5;
  --color-terciary-dark: #774DD6;
  --color-terciary-darker: #6842C2;

  --color-azul-light: #43f6ff;
  --color-azul: #2ef5ff;
  --color-azul-dark: #28d8e0;

  --color-roxo-light: #6f47ff;
  --color-roxo: #5f33ff;
  --color-roxo-dark: #542de0;

  /* --color-secondary: #2ef5ff;
  --color-secondary-dark: #28d8e0; */

  --color-secondary: #5f33ff;
  --color-secondary-dark: #542de0;

  --color-title-in-primary: #FFFFFF;
  --color-text-in-primary: #D4C2FF;
  --color-text-title: #32264D;

  --color-text-complement: #9C98A6;
  --color-text-base: #6A6180;

  --color-line-in-white: #E6E6F0;
  --color-input-background: #F8F8FC;

  --color-button-text: #FFFFFF;

  --color-box-base: #FFFFFF;
  --color-box-footer: #FAFAFC;

  /* font-size: 60%; */
}

@font-face {
  font-family: 'Coolvetica';
  src: url('../fonts/coolvetica.ttf') format('truetype');
}


@font-face {
  font-family: 'Gotham';
  src: url('../fonts/gotham.otf') format('truetype');
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body, #root {
height: 100vh;
}

body {
  background: var(--color-background);
}

#root {
display: flex;
align-items: center;
justify-content: center;
}

body, input, button, textarea {
  font: 500 1rem Montserrat;
  color: var(--color-text-base);
}


.container {
  width: 90vw;
  max-width: 700px;
}

  @media (min-width: 700px) {
  :root {
    /* font-size: 62,5%; */
  }
}

.main-container {
  width: 100vw;
  height: 100vh;

  min-height: 100vh;
  min-height: -webkit-fill-available;

  display: flex;
  flex-direction: row;
  justify-content: center;
}

.main-container .content {
  flex: 1 1 auto;
  height: 100vh;
  overflow-y: auto;

  
}

@media(max-width: 1200px) {
  .main-container {
    flex-direction: column;
    background-color: var(--color-roxo);
  }

  .main-container .content {
    border-radius: 10px 10px 0 0;
    /* border-top-left-radius: 20px;
    border-top-right-radius: 20px; */
    background-color: #fff;
    overflow-y: hidden;

    flex: 1 1 auto;
    /* display: flex; */

    display: flex;


  }

}
