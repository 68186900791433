#mobile-menu-container {
    height: 120px;
    width: 100vw;

    background-color: var(--color-roxo);

    display: flex;
    flex-direction: column;
    align-items: center;
}

#mobile-menu-container-open {
    height: 100vh;
    width: 100vw;

    min-height: 100vh;
    min-height: -webkit-fill-available;

    background-color: var(--color-roxo);

    display: flex;
    flex-direction: column;
    align-items: center;

    position: relative;
}

.mobile-menu-class header {
    height: 120px;
    font-size: 1.2rem;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    width: 100%;
    justify-content: space-between;
    padding: 0 30px;
}

.mobile-menu-class header svg {
    cursor: pointer;
}

.mobile-menu-class header img {
    width: 120px;
    margin-left: 10px;
}



.mobile-menu-class header svg {
    margin: 1rem 0;
    font-size: 1.6rem;
    color: var(--color-azul)
}


.mobile-menu-class ul {
    margin-top: 1.2rem;
    list-style-type: none;
    width: 100%;
}

.mobile-menu-class ul li {
    width: 100%;
    margin: 15px 0;
    color: #fff;
    padding: 0 30px;
}

.mobile-menu-class ul li a svg {
    margin: 0 1rem 0 0;
    font-size: 25px;
    color: #fff
}

.mobile-menu-class ul li a  {
    color: #172B4D;

    text-decoration: none;
    font-weight: 600;

    padding: 8px 4px;

    font-size: 0.8rem;
    transition: opacity 0.2s;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #fff

}

.mobile-menu-class ul li a:hover {
    opacity: 0.6;
}

.mobile-menu-class .sair {
    text-decoration: none;
    font-weight: bold;
    height: 30px;
    cursor: pointer;

    width: 100%;

    color: #fff;


    margin-top: 150px;
    padding-left: 30px;
}

.mobile-menu-class .menu-body {
    background-color: var(--color-roxo);
    width: 100%;
    flex-grow: 1;
}


.mobile-menu-class .menu-body .ilustracoes {
    position: absolute;
    bottom: 0;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    padding: 0 10px;

    width: 100%;

}

.mobile-menu-class .menu-body .ilustracoes > * {
    width: 33%;
    max-width: 200px;
}





@media(min-width: 1200px) {
    .mobile-menu-class, #mobile-menu-container, #mobile-menu-container-open {
        display: none;
    }
}
