.overlay {
    height: 100vh;
    width: 100vw;
    position: absolute !important;
    top: 0;
    left: 0;
    z-index: 999;
}

.overlay-off {
    height: 100vh;
    width: 100vw;
    position: absolute !important;
    z-index: -1;
    top: 0;
    left: 0;
}

.overlay-content {
  z-index: 801;
  color: white;
  position: absolute;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0,0,0,0.3) !important;
  flex-direction: column;
  font-size: 1.2em;
}

.linear-progress {
  width: 100%;
  max-width: 300px;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: #5f33ff !important;
}

.linear-progress div {
  background-color: #2ef5ff !important;

}